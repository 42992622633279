import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import {
	Container,
	TabPane,
	TabContent,
	Nav,
	NavItem,
	NavLink,
	Fa,
	Row,
	Col,
	Modal,
	ModalBody,
} from 'mdbreact';
import classnames from 'classnames';
import './TabComponent.css';
import KredGuruSection from '../KredGuruSection/KredGuruSection';

class TabComponent extends React.Component {
	constructor(props) {
		super(props);

		this.toggle = this.toggle.bind(this);
		this.state = {
			activeItem: 1,
			isOpen: false,
		};
	}

	toggle(tab) {
		if (this.state.activeItem !== tab) {
			this.setState({
				activeItem: tab,
			});
		}
	}

	toggle(tab) {
		if (this.state.activeItem !== tab) {
			this.setState({
				activeItem: tab,
			});
		}
	}

	checkDovsStatus(id, title) {
		if (title !== 'Overview') {
			// Check DOVS Here
			// If true toggle id
			// If false do not toggle id
			let userPassedDovs = localStorage.getItem('userPassedDovs');

			try {
				userPassedDovs = JSON.parse(userPassedDovs);
			} catch (error) {
				userPassedDovs = ['no'];
			}

			if (
				userPassedDovs === null ||
				userPassedDovs === undefined ||
				(userPassedDovs.length > 0 &&
					String(userPassedDovs[0]).toLowerCase() !== 'yes' &&
					(userPassedDovs.length > 0 &&
						String(userPassedDovs[0]).toLowerCase() !== 'manually verified'))
			) {
				this.setState({
					isOpen: true,
				});
			} else {
				this.toggle(id);
			}
		} else {
			this.toggle(id);
		}
	}

	render() {
		const { navTabType, tabs } = this.props;

		const navItems = !tabs
			? null
			: tabs.map((t) => {
					let spanIcon = <div />;
					if (t.imageIcon) {
						spanIcon = (
							<>
								<img
									src={this.state.activeItem === t.id ? t.imageActive : t.imagePassive}
									onMouseEnter={(e) =>
										(e.currentTarget.src =
											this.state.activeItem !== t.id ? t.imagePassive : t.imageActive)
									}
									onMouseOver={(e) =>
										(e.currentTarget.src =
											this.state.activeItem === t.id ? t.imageActive : t.imageRollover)
									}
									onMouseOut={(e) =>
										(e.currentTarget.src =
											this.state.activeItem === t.id ? t.imageActive : t.imagePassive)
									}
									className="rounded-circle credit-report-tabs"
									size="2x"
									alt={t.imageBrand}
									width="90px"
								/>
								<p className="mt-3 tab-title">{t.tabTitle}</p>
							</>
						);
					} else {
						spanIcon = <Fa icon={t.icon} size="2x" />;
					}
					if (t.button) {
						return (
							<NavItem key={t.id}>
								<a
									className="Ripple-parent"
									onClick={() => {
										this.props.function(this.props.data);
									}}
								>
									{spanIcon}

									<br />
									{t.label}
								</a>
							</NavItem>
						);
					} else {
						return (
							<NavItem key={t.id}>
								<NavLink
									to="#"
									className={classnames(
										{
											active: this.state.activeItem === t.id,
										},
										'credit-report-nav-items'
									)}
									onClick={() => {
										this.checkDovsStatus(t.id, t.tabTitle);
									}}
								>
									{spanIcon}
									<br />
									{t.label}
								</NavLink>
							</NavItem>
						);
					}
			  });

		const tabpanes = !tabs
			? null
			: tabs.map((t) => {
					return (
						<TabPane key={t.id} tabId={t.id}>
							{t.tabContent}
						</TabPane>
					);
			  });

		let navTabs = <div />;

		if (navTabType) {
			if (navTabType.type === 'classicTabs') {
				navTabs = (
					<Nav classicTabs color={navTabType.color} className="nav-justified">
						{navItems}
					</Nav>
				);
			} else if (navTabType.type === 'basicTabs') {
				navTabs = (
					<Nav tabs color={navTabType.color} className="nav-justified">
						{navItems}
					</Nav>
				);
			} else if (navTabType.type === 'pillsTabs') {
				navTabs = (
					<Nav pills color={navTabType.color} className="nav-justified ">
						{navItems}
					</Nav>
				);
			} else if (navTabType.type === 'verticalPillsTabs') {
				navTabs = (
					<Nav pills color={navTabType.color} className="flex-column">
						{navItems}
					</Nav>
				);
			}
		}

		return (
			<Router>
				<Container>
					<Row>
						<Col className="col-md-12">
							<div className="classic-tabs">
								{navTabs}
								<TabContent className="card" activeItem={this.state.activeItem}>
									{tabpanes}
								</TabContent>
							</div>
						</Col>
					</Row>
					<Modal className="form-elegant" isOpen={this.state.isOpen} size="lg">
						<ModalBody className="mx-3">
							<KredGuruSection toggleModal={() => this.setState({ isOpen: !this.state.isOpen })} />
						</ModalBody>
					</Modal>
				</Container>
			</Router>
		);
	}
}

export default TabComponent;
