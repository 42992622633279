import React, { Component } from 'react';
import PersonalDetails from './personalDetails';
import NatureOfDispute from './natureOfDispute';
import SupportingDocuments from './supportingDocumnents';
import CommunicationOption from './communicationOption';
import Authorization from './authorization';
import { connect } from 'react-redux';
import { FormWithConstraints } from 'react-form-with-constraints';
import { MDBCard, MDBCardBody, MDBContainer } from 'mdbreact';
import { disputeFormPDF } from '../../../redux/dispute/actionCreators/dispute-form-pdf';
import Stepper from './stepper';
import { fetchDisputeId } from '../../../redux/dispute/actionCreators/getDisputeId';
import { updateNatureDetails } from '../../../redux/dispute/actionCreators/update-nature-data';
import { createDispute } from '../../../redux/dispute/actionCreators/create-dispute';
import { updateContactDetails } from '../../../redux/dispute/actionCreators/update-contact-details';

class OnlineFormDispute extends Component {
	constructor(props) {
		super(props);
		const { user } = this.props.user;

		this.state = {
			formActivePanel1: 1,
			formActivePanel1Changed: false,
			isFormValid: false,
			selectedValue: null,
			personalInfo: {
				workNumber: '',
				userId: user.userId,
				fullName: user.fullName,
				surname: user.familyName,
				idNumber: user.idNumber,
				emailAddress: user.email,
				cellNumber: user.cellphoneNumber,
				postalAddress: '',
				homeNumber: '',
				faxNumber: '',
				date: this.todayDate(),
				residentialAddress: '',
				title: '',
				subjectOfDispute: '',
			},
			natureOfDispute: {
				account: false,
				judgement: false,
				adminOrder: false,
				sequestration: false,
				debtReview: false,
				accountPaidFull: false,
				accountIsClosed: false,
				notMyAccount: false,
				paidMyAccount: false,
				noteOfAdverseListing: false,
				paidJudgement: false,
				notMyJudgement: false,
				judgmentIsIncorrect: false,
				notMyAdminOrder: false,
				adminIsPaid: false,
				adminIsRescind: false,
				notMySequestration: false,
				rehabilitated: false,
				accountIsPrescribed: false,
				debtReviewIsOver: false,
				withdrewDebtReview: false,
				debtReviewFlag: false,
			},
			contact: {
				telephone: false,
				email: false,
			},
			supportingDocuments: {
				selectedIDFile: '',
				selectedProofOfAddress: '',
				selectedProofOfPayment: '',
				selectedCourtOrder: '',
				selectedAffidavit: '',
				selected10XSample: '',
			},
			selectedFile: null,
			isSelectedValue: false,
			files: '',
		};
	}

	componentDidMount() {
		const { disputeId, isLoading } = this.props.disputeId;

		if (!isLoading && Array.isArray(disputeId) && disputeId.length === 0) {
			this.props.fetchDisputeId();
		}
	}

	handleChangeValidationFields = async (target) => {
		const fields = await this.form.validateFields(target);
		const fieldIsValid = fields.every((field) => field.isValid);

		const validateForm = await this.form.validateForm();
		const formIsValid = validateForm.every((field) => field.isValid());

		return {
			fieldIsValid,
			formIsValid,
		};
	};

	handleChange = async (event) => {
		if (event.target) {
			event.preventDefault();
			const {
				target: { name, value },
			} = event;

			this.setState({
				personalInfo: {
					...this.state.personalInfo,
					[name]: value,
				},
			});

			const { fieldIsValid, formIsValid } = await this.handleChangeValidationFields(event.target);

			if (fieldIsValid) {
				this.setState({
					isFormValid: formIsValid,
				});

				console.debug(`Field '${name}' is valid and value is ${value}`);
			} else {
				console.debug(`Field '${name}' is invalid`);
			}
			if (this.form.isValid()) {
				console.debug('The form is valid');
			} else {
				console.debug('The form is invalid');
			}
		}
	};

	getTitleValue = async (value) => {
		this.setState({
			personalInfo: { ...this.state.personalInfo, title: value },
		});
	};

	todayDate = () => {
		let today = new Date();
		const dd = String(today.getDate()).padStart(2, '0');
		const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
		const yyyy = today.getFullYear();
		today = dd + '/' + mm + '/' + yyyy;
		return today;
	};

	nextStep = () => {
		const { step } = this.state;
		this.setState({
			step: step + 1,
		});
	};

	prevStep = () => {
		const { step } = this.state;
		this.setState({
			step: step - 1,
		});
	};

	swapFormActive = (a) => (param) => (e) => {
		this.setState({
			['formActivePanel' + a]: param,
			['formActivePanel' + a + 'Changed']: true,
		});
	};

	handleNextPrevClick = (a) => (param) => (e) => {
		this.setState({
			['formActivePanel' + a]: param,
			['formActivePanel' + a + 'Changed']: true,
		});
	};

	calculateAutofocus = (a) => {
		if (this.state['formActivePanel' + a + 'Changed']) {
			return true;
		}
	};

	submitPersonalInfo = async (event) => {
		event.preventDefault();

		if (event.keyCode === 13) {
			return false;
		}

		const formIsValid = await this.handleFieldsValidation();

		if (formIsValid) {
			this.setState({
				isFormValid: formIsValid,
			});

			const { personalInfo, contact, natureOfDispute } = this.state;
			const data = {
				disputeId: this.props.disputeId.disputeId.uniqueId,
				status: 'Incomplete',
				personalInfo,
				contact,
				natureOfDispute,
			};

			// send consumer data
			await this.props.createDispute(data);
		} else {
			this.setState({
				invalidFormMessage: 'The form is invalid',
			});
		}
	};

	handleNatureSubmit = async (event) => {
		event.preventDefault();

		if (event.keyCode === 13) {
			return false;
		}

		const formIsValid = await this.handleFieldsValidation();

		// Validation library is not working well for radio button selection.
		const hasVal = Object.values(this.state.natureOfDispute).includes(true);

		if (formIsValid && hasVal) {
			this.setState({
				isFormValid: formIsValid,
				invalidFormMessage: '',
			});

			const { personalInfo, contact, natureOfDispute } = this.state;

			const data = {
				disputeId: this.props.disputeId.disputeId.uniqueId,
				status: 'Incomplete',
				natureOfDispute,
				personalInfo,
				contact,
			};

			await this.props.updateNatureDetails(data);
		} else {
			this.setState({
				invalidFormMessage: 'Please indicate the nature of dispute!',
			});
		}
	};

	handleContactSubmit = async (event) => {
		event.preventDefault();

		if (event.keyCode === 13) {
			return false;
		}

		const formIsValid = await this.handleFieldsValidation();

		if (formIsValid) {
			this.setState({
				isFormValid: formIsValid,
			});

			const { personalInfo, contact, natureOfDispute } = this.state;

			const data = {
				disputeId: this.props.disputeId.disputeId.uniqueId,
				status: 'Incomplete',
				natureOfDispute,
				personalInfo,
				contact,
			};

			await this.props.updateContactDetails(data);
		} else {
			this.setState({
				invalidFormMessage: 'The form is invalid',
			});
		}
	};

	handleFieldsValidation = async () => {
		// Validates the non-dirty fields and returns Promise<Field[]>
		const fields = await this.form.validateForm();

		// or simply use this.form.isValid()
		const formIsValid = fields.every((field) => field.isValid());

		return formIsValid;
	};

	handleFinalSubmit = async (event) => {
		event.preventDefault();

		if (event.keyCode === 13) {
			return false;
		}

		const formIsValid = await this.handleFieldsValidation();

		if (formIsValid) {
			this.setState({
				isFormValid: formIsValid,
				invalidFormMessage: '',
			});

			const { personalInfo, contact, natureOfDispute } = this.state;

			const data = {
				template: 'dispute',
				disputeId: this.props.disputeId.disputeId.uniqueId,
				status: 'Submitted',
				personalInfo,
				contact,
				natureOfDispute,
			};

			await this.props.disputeFormPDF(data);
		} else {
			this.setState({
				invalidFormMessage: 'The form is invalid',
			});
		}
	};

	handleSelected = (event) => {
		const {
			target: { checked },
		} = event;

		this.setState({
			isSelectedValue: checked,
		});
	};

	handleCheckChange = async (e) => {
		const { natureOfDispute } = this.state;
		let value = e.target.checked;
		let name = e.target.name;

		let natureOfDisputeUpdated = Object.assign({}, natureOfDispute, {
			[name]: value,
		});

		const { fieldIsValid, formIsValid } = await this.handleChangeValidationFields(e.target);

		if (fieldIsValid) {
			this.setState({
				isFormValid: formIsValid,
				invalidFormMessage: '',
			});
		}

		this.setState({
			natureOfDispute: natureOfDisputeUpdated,
		});

		switch (name) {
			case 'account':
				natureOfDisputeUpdated = Object.assign({}, natureOfDispute, {
					account: true,
					judgement: false,
					adminOrder: false,
					sequestration: false,
					debtReview: false,
				});

				this.setState({
					natureOfDispute: natureOfDisputeUpdated,
				});

				break;
			case 'judgement':
				natureOfDisputeUpdated = Object.assign({}, natureOfDispute, {
					account: false,
					adminOrder: false,
					judgement: true,
					sequestration: false,
					debtReview: false,
				});

				this.setState({
					natureOfDispute: natureOfDisputeUpdated,
				});

				break;
			case 'adminOrder':
				natureOfDisputeUpdated = Object.assign({}, natureOfDispute, {
					judgement: false,
					adminOrder: true,
					account: false,
					sequestration: false,
					debtReview: false,
				});

				this.setState({
					natureOfDispute: natureOfDisputeUpdated,
				});

				break;
			case 'sequestration':
				natureOfDisputeUpdated = Object.assign({}, natureOfDispute, {
					judgement: false,
					adminOrder: false,
					sequestration: true,
					account: false,
					debtReview: false,
				});

				this.setState({
					natureOfDispute: natureOfDisputeUpdated,
				});

				break;
			case 'debtReview':
				natureOfDisputeUpdated = Object.assign({}, natureOfDispute, {
					judgement: false,
					adminOrder: false,
					sequestration: false,
					account: false,
					debtReview: true,
				});

				this.setState({
					natureOfDispute: natureOfDisputeUpdated,
				});

				break;
			default:
				return;
		}
	};

	getSelectedValue = async (value) => {
		this.setState({
			selectedValue: value,
		});
	};

	handleCommWaysCheckBox = (e) => {
		const { contact } = this.state;
		const value = e.target.checked;
		const name = e.target.name;

		const communicationWaysUpdated = Object.assign({}, contact, {
			[name]: value,
		});

		this.setState({
			contact: communicationWaysUpdated,
		});
	};

	render() {
		const {
			personalInfo,
			formActivePanel1,
			isFormValid,
			title,
			natureOfDispute,
			contact,
			invalidFormMessage,
		} = this.state;
		const { disputeId } = this.props.disputeId;

		return (
			<MDBContainer>
				<MDBCard>
					<MDBCardBody>
						<h5 style={{ fontSize: '25px' }} className="text-center title-2 pt-3 pb-3 mb-3">
							Dispute / Query Form ({this.props.disputeId.disputeId.uniqueId})
						</h5>
						<Stepper swapFormActive={this.swapFormActive} formActivePanel1={formActivePanel1} />
						<FormWithConstraints
							className="dispute"
							id="dispute-form"
							ref={(formWithConstraints) => (this.form = formWithConstraints)}
							noValidate
						>
							{formActivePanel1 === 1 && (
								<PersonalDetails
									nextStep={this.handleNextPrevClick(1)(2)}
									prevStep={this.prevStep}
									isFormValid={isFormValid}
									handleChange={this.handleChange}
									personalInfo={personalInfo}
									submitPersonalInfo={this.submitPersonalInfo}
									addDispute={this.props.addDispute}
								/>
							)}
							{formActivePanel1 === 2 && (
								<NatureOfDispute
									nextStep={this.handleNextPrevClick(1)(3)}
									prevStep={this.handleNextPrevClick(1)(1)}
									isFormValid={isFormValid}
									invalidFormMessage={invalidFormMessage}
									handleCheckChange={this.handleCheckChange}
									personalInfo={personalInfo}
									handleChange={this.handleChange}
									natureOfDispute={natureOfDispute}
									handleNatureSubmit={this.handleNatureSubmit}
									updateNature={this.props.updateNature}
									selectedValue={this.state.selectedValue}
									getSelectedValue={this.getSelectedValue}
									handleSelected={this.handleSelected}
								/>
							)}
							{formActivePanel1 === 3 && (
								<SupportingDocuments
									nextStep={this.handleNextPrevClick(1)(4)}
									prevStep={this.handleNextPrevClick(1)(2)}
									disputeId={disputeId.uniqueId}
									userId={personalInfo.userId}
									isFormValid={isFormValid}
									natureOfDispute={this.state.natureOfDispute}
								/>
							)}
							{/* Previous Step For Contact Details */}
							{/* {formActivePanel1 === 4 && (
								<CommunicationOption
									nextStep={this.handleNextPrevClick(1)(5)}
									prevStep={this.handleNextPrevClick(1)(3)}
									handleCommWaysCheckBox={this.handleCommWaysCheckBox}
									contact={contact}
									handleContactSubmit={this.handleContactSubmit}
									updateContact={this.props.updateContact}
								/>
							)} */}
							{formActivePanel1 === 4 && (
								<Authorization
									prevStep={this.handleNextPrevClick(1)(3)}
									isFormValid={isFormValid}
									personalInfo={personalInfo}
									todayDate={this.todayDate()}
									handleChange={this.handleChange}
									title={title}
									getTitleValue={this.getTitleValue}
									handleSubmit={this.handleFinalSubmit}
									pdfGenerator={this.props.pdfGenerator}
									updateContact={this.props.updateContact}
									addDispute={this.props.addDispute}
									updateNature={this.props.updateNature}
									fileUpload={this.props.fileUpload}
								/>
							)}
						</FormWithConstraints>
					</MDBCardBody>
				</MDBCard>
			</MDBContainer>
		);
	}
}

const mapDispatchToProps = (dispatch) => ({
	disputeFormPDF: (data) => dispatch(disputeFormPDF(data)),
	updateNatureDetails: (data) => dispatch(updateNatureDetails(data)),
	updateContactDetails: (data) => dispatch(updateContactDetails(data)),
	createDispute: (data) => dispatch(createDispute(data)),
	fetchDisputeId: () => dispatch(fetchDisputeId()),
});

const mapStateToProps = (state) => {
	return {
		pdfGenerator: state.pdfGenerator,
		disputeId: state.disputeId,
		updateNature: state.updateNature,
		updateContact: state.updateContact,
		addDispute: state.addDispute,
		fileUpload: state.fileUpload,
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(OnlineFormDispute);
