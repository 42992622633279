import React, { Component } from 'react';
import OverviewContent from '../../contents/detailed-report/Overview/OverviewContent';
import PersonalInformationContent from '../../contents/detailed-report/personalInformation/PersonalInformationContent';
import ShortTermAndRevolvingCreditAccounts from '../../contents/detailed-report/ShortTermAndRevolvingCreditAccounts/ShortTermAndRevolvingCreditAccounts';
import MediumAndLongTermCreditAccounts from '../../contents/detailed-report/MediumAndLongTermCreditAccounts/MediumAndLongTermCreditAccounts';
import AdverseListingContent from '../../contents/detailed-report/AdverseListing/AdverseListingContent';
import TabComponent from '../../../components/Tab/TabComponent';
import './TabsSection.css';
import CIPCAndDeedsContent from '../../contents/detailed-report/CIPCAndDeeds/CIPCAndDeeds';
import { FeatureToggle } from 'react-feature-toggles/lib';
import { FEATURE_TOGGLE_LIST } from '../../../shared/featureToggles';
import EnquiryHistoryContent from '../../contents/detailed-report/Enquiries/enquiries';
import CreditReportDownloadPdfFormRequest from '../../contents/detailed-report/CreditReportDownloadRequest/creditReportDownloadRequest';

class TabsSection extends Component {
	constructor(props) {
		super(props);
		this.state = {
			navTabType: {
				classicTabs: { type: 'classicTabs', color: '' },
				basicTabs: { type: 'basicTabs', color: '' },
				pillsTabs: { type: 'pillsTabs', color: '' },
				verticalPillsTabs: { type: 'verticalPillsTabs', color: '' },
			},
		};
	}

	render() {
		return (
			<TabComponent
				navTabType={{ type: 'classicTabs', color: '' }}
				tabs={[
					{
						id: 1,
						imageIcon: true,
						imagePassive: 'assets/img/cr_overview.png',
						imageActive: 'assets/img/cr_overview_active.png',
						imageRollover: 'assets/img/cr_overview_active.png',
						imageBrand: 'overview',
						tabTitle: 'Overview',
						tabContent: <OverviewContent consumer={this.props.consumer} />,
					},
					{
						id: 2,
						imageIcon: true,
						imagePassive: 'assets/img/cr_pers_info.png',
						imageActive: 'assets/img/cr_pers_info_active.png',
						imageRollover: 'assets/img/cr_pers_info_active.png',
						imageBrand: 'personal information',
						tabTitle: 'Personal Information',
						tabContent: <PersonalInformationContent consumer={this.props.consumer} />,
					},
					{
						id: 3,
						imageIcon: true,
						imagePassive: 'assets/img/cr_revolving.png',
						imageActive: 'assets/img/cr_revolving_active.png',
						imageRollover: 'assets/img/cr_revolving_active.png',
						mageBrand: 'credit accounts',
						tabTitle: 'Revolving and Short Term Credit',
						tabContent: <ShortTermAndRevolvingCreditAccounts consumer={this.props.consumer} />,
					},
					{
						id: 4,
						imageIcon: true,
						imagePassive: 'assets/img/cr_medium_credit.png',
						imageActive: 'assets/img/cr_medium_credit_active.png',
						imageRollover: 'assets/img/cr_medium_credit_active.png',
						imageBrand: 'loans',
						tabTitle: 'Medium to Long Term Credit',
						tabContent: <MediumAndLongTermCreditAccounts consumer={this.props.consumer} />,
					},
					{
						id: 5,
						imageIcon: true,
						imagePassive: 'assets/img/cr_adverse_records.png',
						imageActive: 'assets/img/cr_adverse_records_active.png',
						imageRollover: 'assets/img/cr_adverse_records_active.png',
						imageBrand: 'adverse listing',
						tabTitle: 'Adverse Records',
						tabContent: <AdverseListingContent consumer={this.props.consumer} />,
					},
					{
						id: 6,
						imageIcon: true,
						imagePassive: 'assets/img/cr_directorships.png',
						imageActive: 'assets/img/cr_directorships_active.png',
						imageRollover: 'assets/img/cr_directorships_active.png',
						imageBrand: 'cipc and deeds',
						tabTitle: 'Directorships and Properties',
						tabContent: <CIPCAndDeedsContent consumer={this.props.consumer} />,
					},
					{
						id: 7,
						imageIcon: true,
						imagePassive: 'assets/img/cr_previous_enquiries.png',
						imageActive: 'assets/img/cr_previous_enquiries_active.png',
						imageRollover: 'assets/img/cr_previous_enquiries_active.png',
						imageBrand: 'previous enquiries',
						tabTitle: 'Previous Enquiries',
						tabContent: <EnquiryHistoryContent consumer={this.props.consumer} />,
					},
					// {
					// 	id: 8,
					// 	button: false,
					// 	imageIcon: true,
					// 	imagePassive: 'assets/img/PDF Download Icon passive state.svg',
					// 	imageActive: 'assets/img/PDF Download Icon active state.svg',
					// 	imageRollover: 'assets/img/PDF Download Icon rollover state.svg',
					// 	imageBrand: 'Download PDF',
					// 	tabContent: (
					// 		<CreditReportDownloadPdfFormRequest user={this.props.user} />
					// 	)
					// }
				]}
				// function={this.props.downloadReportPDF} data={this.props.consumer.consumer}
			/>
		);
	}
}

export default TabsSection;
