import React, { Component } from 'react';
import { connect } from 'react-redux';
import CryptoJS from 'crypto-js';
import { Container, Row, Col, Card, CardBody, Modal, ModalBody } from 'mdbreact';
import FeaturedSection from '../../sections/Featured/FeaturedSection';
import JustForYouSection from '../../sections/JustForYou/JustForYouSection';
import CreditScoreSection from '../../sections/Dashboard/CreditScoreSection';
import KredGuruSection from '../../sections/KredGuru/KredGuruSection';
import { Loading } from '../../../components/Loading/LoadingComponent';
import { ErrorComponent } from '../../../components/Error/ErrorComponent';
import { FeatureToggle } from 'react-feature-toggles/lib';
import { FEATURE_TOGGLE_LIST } from '../../../shared/featureToggles';
import { downloadReportPDF, fetchConsumer } from '../../../redux/ActionCreators';
import { fetchIdHeroUserNotification } from '../../../redux/idHeroNotifications/actionCreators/id-hero-user-notification';
import { withRouter } from 'react-router-dom';
import GetExceptionCode from '../../components/Main/exceptionCode';
import './Dashboard.css';
import KredGuruSectionEmailVerify from '../../../components/KredGuruSectionEmailVerify/KredGuruSectionEmailVerify';

const mapStateToProps = (state) => {
	return {
		consumer: state.consumer,
		user: state.user,
		idHeroNotification: state.idHeroNotification,
	};
};
const mapDispatchToProps = (dispatch) => ({
	downloadReportPDF: (consumer) => {
		dispatch(downloadReportPDF(consumer));
	},
	fetchConsumer: () => {
		dispatch(fetchConsumer());
	},
	fetchIdHeroUserNotification: () => {
		dispatch(fetchIdHeroUserNotification());
	},
});

class DashboardPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			dashboardKGCount: 0,
			consumer: [],
			isOpen: false,
		};
	}
	componentDidMount = () => {
		this.setState((prevState) => {
			if (prevState) {
				return { dashboardKGCount: prevState.dashboardKGCount + 1 };
			}
		});
		const urlParams = new URLSearchParams(window.location.search);
		const session_state = urlParams.get('session_state');
		const initialLogin = urlParams.get('initial');

		if (session_state) localStorage.setItem('session_state', session_state);
		/** This toDashboard key is set in an  action creator(fetch user). To avoid an infinite loop, the API call needs to be inside a conditional statement. **/
		if (localStorage.getItem('toDashboard') === 'true') {
			const data = localStorage.getItem('persist:persistedStore');
			const savedData = JSON.parse(data);
			const decrypted = CryptoJS.AES.decrypt(
				`${savedData.consumer}`.replace('"', ''),
				'Splendi-ui'
			).toString(CryptoJS.enc.Utf8);
			const decryptedDataJSONFormat = JSON.parse(decrypted);

			if (decryptedDataJSONFormat.consumer.length === undefined) {
				localStorage.setItem('toDashboard', 'false');
				this.setState({ savedData });
			} else {
				localStorage.setItem('toDashboard', 'false');
				this.props.fetchConsumer();
				this.props.fetchIdHeroUserNotification();
			}
		}

		const keysLength = Object.keys(this.props.user).length;

		if (
			this.props.user &&
			keysLength > 0 &&
			this.props.user.user.emailVerified === false &&
			this.props.consumer.consumer !== undefined &&
			this.props.consumer.consumer !== null &&
			this.props.consumer.consumer.AKANames !== undefined &&
			this.props.consumer.consumer.AKANames !== null &&
			this.props.timeOutStatus === false &&
			initialLogin === 'true'
		) {
			this.setState({ ...this.state, isOpen: true });
		}
	};

	render() {
		let idHeroNotification = this.props.idHeroNotification.idHeroNotification;
		/**idHeroNotification = [];
		 I cannot really test this because this api is using a mock data. date and time are same.
		 const idHeroNotificationSortedByDate = idHeroNotification.slice().sort((a, b) => b.CreatedOnDate -a.CreatedOnDate);
		 console.log("idHeroNotificationSortedByDate ", idHeroNotificationSortedByDate);
		 **/
		let kredGuru = null;
		let idHeroContent = null;

		if (
			this.props.consumer.isLoading ||
			this.props.user.isLoading ||
			this.props.idHeroNotification.isLoading
		) {
			kredGuru = <Loading />;
			idHeroContent = <Loading />;
		} else if (this.props.consumer.errMess || this.props.user.errMess) {
			const msg =
				'We have not found a credit history for this passport number. This might be because you have not applied for a credit account in South Africa using this passport number. ';
			kredGuru = <ErrorComponent msg={msg} />;
			idHeroContent = <ErrorComponent msg={msg} />;
		} else {
			let kredImage = null;
			const consumer = this.props.consumer.consumer;
			if (consumer.ConsumerScoring.RiskCategory.value === 'Medium Risk') {
				kredImage = 'kred_guru_-1.svg';
			} else if (consumer.ConsumerScoring.RiskCategory.value === 'Potential High Risk') {
				kredImage = 'kred_guru_-3.svg';
			} else if (consumer.ConsumerScoring.RiskCategory.value === 'Low Risk') {
				kredImage =
					Number(consumer.ConsumerScoring.FinalScore.value) < 840
						? 'kred_guru_+2.svg'
						: 'kred_guru_+2.svg';
			}
			let signature = this.props.dashboardKGCount === 0 ? 'KG – Splendi’s Kred Guru' : null;
			const kgMessage = [
				"I'm here to keep you informed, and help you grow your Cred Rep! The Dashboard gives you\n" +
					'quick access to your credit score, and like everything on Splendi, a whole lot more...',
				'Our innovative tools are yours to use to grow your credit score, minimise your risk profile\n' +
					'and keep tabs on your account activity by reviewing your 24-month Payment History.',
				'If you head over to Featured Offers, you can explore deals and offers designed to suit your\n' +
					'pocket and often – won’t find anywhere else.',
				'On the move? Buy airtime, data and electricity through your Dashboard to easily\n' +
					'stay connected and keep the lights on.',
			];
			const firstName = this.props.user.user.givenName;
			let header = this.props.dashboardKGCount === 0 ? `Hi ${firstName}` : null;
			if (this.props.dashboardKGCount === kgMessage.length) {
				this.props.resetDashboardKGCount();
			}
			let content = kgMessage[this.props.dashboardKGCount];
			if (Number(consumer.ConsumerScoring.FinalScore.value) === 0) {
				const kgMessageForZeroCreditScore = `${firstName} your score is 0, because ${GetExceptionCode(
					consumer.ConsumerScoring.Exception_Code.value
				)}`;
				kredGuru = (
					<KredGuruSection
						id="DashboardKG"
						kredGuruImage={kredImage}
						header={null}
						content={kgMessageForZeroCreditScore}
						signature={signature}
					/>
				);
			} else {
				kredGuru = (
					<KredGuruSection
						id="DashboardKG"
						kredGuruImage={kredImage}
						header={header}
						content={content}
						signature={signature}
					/>
				);
			}

			if (idHeroNotification.length > 0) {
				idHeroContent = (
					<ol
						style={{
							overflowY: 'scroll',
							height: '100px',
						}}
					>
						{idHeroNotification.map((msg) => {
							return (
								<li key={msg.NotificationMessage}>
									You have{' '}
									<span style={{ color: 'red' }}>
										{' '}
										{msg.NotificationMessage.replace('You have ', '')}{' '}
									</span>
								</li>
							);
						})}
					</ol>
				);
			} else {
				idHeroContent = (
					<>
						<p style={{ textAlign: 'center' }}>You currently have no notifications</p>
					</>
				);
			}
		}

		return (
			<Container className="mt-2">
				<Card className="p-4">
					<CardBody>
						<Row>
							<Col className="col-12 col-md-6">
								<h4 className="dashboardTitle mb-md-2">Welcome to Splendi!</h4>
								<FeatureToggle featureName={FEATURE_TOGGLE_LIST.SPLENDI_LITE} showOnlyWhenDisabled>
									<p>
										Welcome to Splendi! Your credit score is a reflection of your credit reputation.
										Splendi's innovative tools will help you build and sustain a solid credit
										report.
									</p>
								</FeatureToggle>
								<FeatureToggle featureName={FEATURE_TOGGLE_LIST.SPLENDI_LITE}>
									<p>
										Welcome to Splendi Lite! Your credit score is a reflection of your credit
										reputation. Splendi's innovative tools will help you build and sustain a solid
										credit report.
									</p>
								</FeatureToggle>
								<div
									style={{
										width: '100%',
										border: '2px solid #ccc',
										borderRadius: '5px',
									}}
									className="idHeroContainer my-md-0"
								>
									<a href="#" style={{ marginBottom: '1rem' }}>
										<img
											src="assets/img/idhero_logo_wide.svg"
											className="rounded ml-2 mr-2 mb-3 mt-2 id-hero"
											alt="aligment"
										/>{' '}
									</a>
									{idHeroContent}
								</div>
							</Col>

							<Col className="col-12 col-md-6 mt-2 mt-md-0 dialogBox">{kredGuru}</Col>
						</Row>
						<Row className="mt-4">
							<CreditScoreSection consumer={this.props.consumer} />
							<Col className="col-12 col-md-6">
								<FeaturedSection />
								<JustForYouSection />
							</Col>
						</Row>
					</CardBody>
				</Card>
				<Modal className="form-elegant" isOpen={this.state.isOpen} size="lg">
					<ModalBody className="mx-3">
						<KredGuruSectionEmailVerify
							toggleModal={() => this.setState({ ...this.state, isOpen: !this.state.isOpen })}
						/>
					</ModalBody>
				</Modal>
			</Container>
		);
	}
}

export default withRouter(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(DashboardPage)
);
